import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import SectionHeader, { ISectionHeaderProps } from '@components/common/SectionHeader';

import styles from './styles';

interface ISectionProps extends ISectionHeaderProps {
  title: string | JSX.Element;
  className?: string;
}

const Section: React.FunctionComponent<ISectionProps & PropsWithChildren> = ({
                                                           children,
  className,
                                                           ...props
                                                         }) => {
  const classes = styles();
  return (
    <div className={classNames(classes.section, className)}>
      <SectionHeader {...props}>
        {children}
      </SectionHeader>
    </div>
  );
};

export default Section;
