import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

interface IDropdownStylesProps {
  error: boolean;
  isOpen: boolean;
}

export default makeStyles<Theme, IDropdownStylesProps>((theme) =>
  createStyles({
    arrowBig: {
      width: 25,
    },
    disabled: {},
    dropdown: {
      '&$disabled': {
        '& $select': {
          cursor: 'not-allowed',
        },
        opacity: '0.3',
      },
      '&$dropdownBig': {
        height: 52,
        padding: 7,
        width: '100%',
      },
      '&$dropdownImages': {
        border: 'none',
      },
      '&$dropdownWithRenderValue': {
        backgroundColor: ColorsPalette.neutral1,
        border: '2px solid' + ColorsPalette.neutral1,
      },
      backgroundColor: (props) => props.isOpen ? ColorsPalette.neutral2 : (props.error ? ColorsPalette.salesLight : ColorsPalette.neutral1),
      border: (props) => props.isOpen ? '2px solid ' + ColorsPalette.primary : (props.error ? '2px solid ' + ColorsPalette.salesLight : '2px solid ' + ColorsPalette.neutral1),
      borderRadius: '12px',
      color: ColorsPalette.tertiary,
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      height: '40px',
      letterSpacing: '0em',
      lineHeight: '22px',
      //  margin: '50px 0',
      textAlign: 'right',
      width: '72px',
      [theme.breakpoints.down('md')]: {
        margin: '0',
        width: '68px',
      },
    },
    dropdownBig: {},
    dropdownImageValue: {
      borderRadius: '12px',
      height: '40px',
      width: '72px',
      [theme.breakpoints.down('md')]: {
        width: '68px',
      },
    },
    dropdownImages: {
      '& $select': {
        padding: '0',
      },
    },
    dropdownItem: {
      backgroundColor: ColorsPalette.neutral2,
      color: ColorsPalette.tertiary,
      margin: '0',
    },
    dropdownValue: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    dropdownWithRenderValue: {},
    icon: {
      right: '10px',
      top: 'calc(50% - 4px)',
    },
    list: {
      borderRadius: '12px',
    },
    menuImageItem: {
      height: '25px',
      width: '100%',
    },
    menuItem: {
      '&$menuItemSelected': {
        '&:after': {
          content: '+',
          display: 'block',
          height: '10px',
          width: '10px',
        },
        '&:hover': {
          backgroundColor: ColorsPalette.coralPinkLight,
        },
        backgroundColor: ColorsPalette.neutral2,
      },
      '&:hover': {
        backgroundColor: ColorsPalette.coralPinkLight,
      },
      color: ColorsPalette.primary,
    },
    menuItemIcon: {
      marginBottom: -7,
      marginRight: 7,
      width: 20,
    },
    menuItemSelected: {
      '&:after': {
        content: '+',
      },
      '&:hover': {
        backgroundColor: ColorsPalette.coralPink,
      },
      backgroundColor: ColorsPalette.neutral2,
    },
    menuItemSelectedIcon: {
      marginLeft: 'auto',
      paddingLeft: '4px',
      width: '12px',
    },
    paper: {
      border: '1px solid ' + ColorsPalette.neutral1,
      borderRadius: '12px',
      boxShadow: '-0.948454px -0.948454px 3.79381px rgba(255, 255, 255, 0.05), 4px 4px 12px rgba(0, 0, 0, 0.12)',
      padding: '19px 0',
    },
    root: {},
    select: {
      ...{
        display: 'flex',
        paddingLeft: '9px',
        textAlign: 'center',
      },
    },
    selectBig: {
      color: ColorsPalette.primary,
      fontFamily: FontCollection.raleway,
      fontSize: 16,
      fontWeight: 500,
    },
  }));
