import classNames from 'classnames';
import React, { ReactNode, SyntheticEvent, useState } from 'react';

import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import ArrowBottomIOS24pxIcon from '@icons/ArrowBottom_IOS_24px';
import { Check24Icon } from '@icons/check24';
import DropdownIcon from '@icons/DropdownIcon';
import dropdownStyles from './styles';

interface IOption {
  icon?: ReactNode;
  name: string | JSX.Element;
  value: string | number;
}

interface IDropDownProps {
  error?: boolean;
  defaultRenderValue?: string | JSX.Element;
  disabled?: boolean;
  styles?: any;
  id: string;
  isBig?: boolean;
  isImagesDropDown?: boolean;
  options: IOption[];
  onChange?: (value: any) => void;
  value?: string | number | null;
  withIcon?: boolean;
  withoutSelectedValue?: boolean;
}

const Dropdown: React.FunctionComponent<IDropDownProps> = ({
  error = false,
  disabled = false,
  isBig = false,
  isImagesDropDown = false,
  styles,
  id,
  options,
  onChange,
  value,
  withIcon = false,
  withoutSelectedValue = false,
  defaultRenderValue = '',
}) => {
  const [isOpen, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClickListItem = (event: SyntheticEvent<HTMLElement>) => {
    if (event?.currentTarget)
      setAnchorEl(event?.currentTarget);
  };
  const classes = dropdownStyles({ error, isOpen });

  const changeValue = (e) => {
    if (onChange && (((value || withoutSelectedValue) && e?.target?.value !== value) || (!withoutSelectedValue && !value))) {
      onChange(e.target.value);
    }
  };

  const iconComponent = (props) => {
    return isBig ? <ArrowBottomIOS24pxIcon {...props} /> : <DropdownIcon {...props} />;
  };

  const selectedItem = options && value ? options.find((optionItem) => optionItem.value === value) : null;
  return (
    <Select
      disabled={disabled}
      defaultValue={1}
      displayEmpty={true}
      value={selectedItem ? value : ''}
      onChange={(e) => changeValue(e)}
      onClose={() => setOpen(false)}
      onOpen={(e) => {
        setOpen(true);
        handleClickListItem(e as SyntheticEvent<HTMLElement>);
      }}
      className={classNames(
        classes.dropdown,
        { [classes.dropdownBig]: isBig },
        { [classes.dropdownImages]: isImagesDropDown },
        { [classes.disabled]: disabled },
        { [classes.dropdownWithRenderValue]: defaultRenderValue },
        styles,
      )}
      classes={{ icon: classes.icon, select: classNames(classes.select, { [classes.selectBig]: isBig }) }}
      input={<InputBase />}
      inputProps={{ 'aria-label': 'naked' }}
      IconComponent={iconComponent}
      MenuProps={{
        anchorEl,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        classes: {
          list: classes.list,
          paper: classes.paper,
        },
        transformOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      }}
      renderValue={() =>
        isImagesDropDown ? (
          <div className={classes.dropdownImageValue}
            //   style={{ background: generateItemBackgroundValue(selectedItem?.name) }}
          />
        ) : (
          (selectedItem && <>{withIcon && selectedItem.icon &&
            <div className={classes.menuItemIcon}>{selectedItem.icon}</div>}
            <div className={classes.dropdownValue}>{selectedItem.name}</div>
          </>) || defaultRenderValue
        )
      }
    >
      {options &&
        options.map((item) => (
          <MenuItem
            key={`${id}-menuItem-${item.value}`}
            className={classes.dropdownItem}
            classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}
            value={item.value}
          >
            {isImagesDropDown ? (
              <div className={classes.menuImageItem}
                // style={{ background: generateItemBackgroundValue(item) }}
              />
            ) : (
              <>
                {withIcon && item.icon && <div className={classes.menuItemIcon}>{item.icon}</div>}
                {item.name}
                {value && value === item.value ? <Check24Icon className={classes.menuItemSelectedIcon} /> : null}
              </>
            )}
          </MenuItem>
        ))}
    </Select>
  );
};

export default Dropdown;
