import { EGender } from '@constants/gender';
import React from 'react';

export interface IGenderContext {
  sexId: number | EGender;
}

const defaultValues: IGenderContext = {
  sexId: EGender.women
};

export const GenderContext = React.createContext<IGenderContext>(defaultValues);
