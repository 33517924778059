import React, { ReactNode } from 'react';

import Hidden from '@components/common/Hidden';
import ArrowForwardIcon from '@icons/ArrowForward';

import styles from './styles';

interface IViewAllButtonProps {
  label: string | ReactNode;
  labelMobile?: string | ReactNode;
  onClick: () => void;
  showDesktop?: boolean;
  showMobile?: boolean;
}

const AdditionalAction: React.FunctionComponent<IViewAllButtonProps> = ({
                                                                          label,
                                                                          labelMobile = <ArrowForwardIcon />,
                                                                          onClick,
                                                                          showDesktop = true,
                                                                          showMobile = true,
                                                                        }) => {
  const classes = styles();
  return (
    <div onClick={onClick} className={classes.viewAll}>
      {showDesktop && (
        <Hidden mdDown={true}>
          <span>{label}</span>
        </Hidden>
      )}
      {showMobile && (
        <Hidden mdUp={true}>
          {labelMobile}
        </Hidden>
      )}
    </div>
  );
};

export default AdditionalAction;
