import React from 'react';

const DropdownIcon: React.FunctionComponent<{ className?: string; }> = ({ className }) => {
  return (
    <svg className={className} width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.934219 0.246093L0.04922 1.13109L4.99922 6.08109L9.94922 1.13109L9.06422 0.246094L4.99922 4.31109L0.934219 0.246093Z"
        fill="#7A7A7A" />
    </svg>
  );
};

export default DropdownIcon;
