import React from 'react';

const ArrowBottomIOS24pxIcon: React.FunctionComponent<{ className?: string; onClick?: (e) => void; }> = ({ className, onClick }) => {
  return (
    <svg onClick={onClick} className={className} width="14" height="9" viewBox="0 0 14 9" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.58156 0.328124L0.401564 1.50812L7.00156 8.10812L13.6016 1.50813L12.4216 0.328125L7.00156 5.74812L1.58156 0.328124Z"
        fill="black" />
    </svg>

  );
};

export default ArrowBottomIOS24pxIcon;
