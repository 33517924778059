import React, { PropsWithChildren, useState } from 'react';

import Dropdown from '@components/common/dropdowns/Dropdown';
import { EGender } from '@constants/gender';
import { useTranslation } from '@hooks/useTranslation';
import { IGetCustomerResponse } from '@interfaces/auth/reducer';
import { IStore } from '@interfaces/store';
import { mapGenderIdToOptionIndex } from '@utils/gender';
import { GenderContext } from 'context/gender';
import { useSelector } from 'react-redux';
import AdditionalAction from './AdditionalAction';

import TopBarStyles from './styles';

export interface ISectionHeaderProps {
  title: string | JSX.Element;
  showGenderCategories?: boolean;
  showViewAllButton?: boolean;
  showClearAllButton?: boolean;
  onClearAllButtonClick?: () => Promise<void> | null;
}

const SectionHeader: React.FunctionComponent<ISectionHeaderProps & PropsWithChildren> = ({
                                                                children,
                                                                title,
                                                                showGenderCategories = true,
                                                                showViewAllButton = false,
                                                                showClearAllButton = false,
                                                                onClearAllButtonClick = null,
                                                              }) => {
  const classes = TopBarStyles();
  const { t } = useTranslation();
  const user: null | IGetCustomerResponse = useSelector((state: IStore) => state.auth.user);
  const defaultOption = !!user?.data?.attributes?.gender ? mapGenderIdToOptionIndex(user?.data?.attributes?.gender) : EGender.women;

  const [value, changeValue] = useState(defaultOption);

  const items = [
    { value: EGender.man, name: t('common.optionForMen')},
    { value: EGender.women, name: t('common.optionForWomen')},
    { value: EGender.boy, name: t('common.optionForBoys') },
    { value: EGender.girl, name: t('common.optionForGirls') },
  ];
  return (
    <>
      {title && (
        <div className={classes.sectionHeader}>
          <div className={classes.sectionHeaderBase}>
            <div className={classes.sectionHeaderTitle}>{title}</div>
            {showGenderCategories && <div className={classes.sectionHeaderSelect}>
              <Dropdown id={'sexId'} styles={classes.dropdownItem} onChange={changeValue} options={items}
                        value={value} />
            </div>}
          </div>
          <div className={classes.sectionHeaderAdditional}>
            {showViewAllButton && <AdditionalAction onClick={() => {
              // TODO: handle on click event
            }} label={t('common.view_all')} />}
            {showClearAllButton && onClearAllButtonClick &&
            <AdditionalAction onClick={onClearAllButtonClick} label={t('common.clear_all')}
                              labelMobile={t('common.clear_all')} />}
          </div>
        </div>
      )}
      <GenderContext.Provider value={{ sexId: value }}>
        {children}
      </GenderContext.Provider>
    </>
  );
};

export default SectionHeader;
