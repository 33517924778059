import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles((theme) =>
  createStyles({
    section: {
      [theme.breakpoints.up('md')]: {
        aspectRatio: 2.28,
      },
      aspectRatio: 0.765,
      display: 'flex',
      flex: '1 1 100%',
      flexDirection: 'column',
      transition: 'all 0.5s ease-out',
      width: '100%',
    },
  }));
