import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';
import { TypographyCollection } from '@themes/font';

export default makeStyles<Theme>((theme) =>
  createStyles({
    dropdownItem: {
      '&:hover': {
        backgroundColor: ColorsPalette.neutral1,
        border: '2px solid ' + ColorsPalette.neutral1,
        color: ColorsPalette.hoverActionCTA,
      },
      backgroundColor: 'transparent',
      border: '2px solid transparent',
      color: ColorsPalette.primary,
      cursor: 'pointer',
      width: 'auto',
    },
    sectionHeader: {
      [theme.breakpoints.down('md')]: {
        marginBottom: 0,
        maxHeight: 80,
        minHeight: 68,
        padding: '0 16px',
      },
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      height: 56,
      marginBottom: 33,
    },
    sectionHeaderAdditional: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      marginLeft: 'auto',
    },
    sectionHeaderBase: {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    sectionHeaderSelect: {
      [theme.breakpoints.down('md')]: {
        margin: '-3px 0 0 -8px',
      },
      margin: '6px 0 0 6px',
    },
    sectionHeaderTitle: {
      [theme.breakpoints.down('md')]: {
        ...TypographyCollection.HeadingMobileM,
      },
      ...{
        display: 'flex',
      },
      ...TypographyCollection.HeadingDesktopM,
    },
    [theme.breakpoints.up('sm')]: {
      titleHolder: {
        alignItems: 'flex-end',
        display: 'flex',
      },
      topBar: {
        ...{
          alignItems: 'flex-end',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1rem',
          padding: '1rem 10px',
        },
        ...TypographyCollection.HeadingDesktopM,
      },
    },
    [theme.breakpoints.down('md')]: {
      titleHolder: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
      topBar: {
        ...{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1rem',
          padding: '1rem 10px',
        },
        ...TypographyCollection.HeadingMobileM,
      },
    },
  }),
);
