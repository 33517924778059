import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';

export default makeStyles<Theme>((theme) =>
  createStyles({
    viewAll: {
      [theme.breakpoints.down('md')]: {
        margin: '9px 0 0 0',
      },
      '& svg': {
        '& path': {
          fill: ColorsPalette.primary,
        },
        height: 16,
        width: 16,
      },
      cursor: 'pointer',
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '160%',
      margin: '15px 2px 0 0',
      textDecorationLine: 'underline',
    },
  }),
);
